<template>
  <v-row justify="center">
    <v-dialog :value="editItemModal" persistent width="500">
      <v-card>
        <v-row no-gutters>
          <v-col>
            <a
              @click="
                deleteOrderItem({
                  order_id: thisItem.order_id,
                  item_id: thisItem._id
                })
              "
            >
              <img src="/img/delete.svg" id="delete" />
            </a>
          </v-col>
          <v-card-title v-if="thisItem.client">{{
            thisItem.client
          }}</v-card-title>
          <v-card-title v-else>
            <v-text-field
              name="itemName"
              label="Artikel"
              v-model="thisItem.name"
              >{{ thisItem.name }}</v-text-field
            >
          </v-card-title>
          <v-col>
            <a @click="incLabelCopies()">
              <v-icon large id="incLabelCopies">mdi-text-box-plus</v-icon>
              <span id="labelCopies">{{labelCopies}}</span>
            </a>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col v-if="thisItem.client">{{ thisItem.name }}</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="text-center display-2 font-weight-bold"
            >{{ thisItem.amount }} {{ thisItem.unit }}</v-col
          >
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex justify-end"
            ><v-checkbox
              label="Prijsafpraak"
              v-model="saveFixedPrice"
            ></v-checkbox
          >
          <v-checkbox
          label="Hele getallen"
          v-model="wholeNumbers"
          @change="setWholeNumbers"
          ></v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="clearAll"
              >C</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="delOne"
              >⇦</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              :color="setprice ? 'green' : 'accent'"
              tile
              @click="setPrice"
              >Prijs</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              :color="setprice ? 'green' : 'accent'"
              tile
              @click="setPrice"
              >{{ toCurrency(thisItem.price) }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(7)"
              >7</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(8)"
              >8</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(9)"
              >9</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setMinus = true"
              >-</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(4)"
              >4</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(5)"
              >5</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(6)"
              >6</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="addTotal"
              >+</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(1)"
              >1</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(2)"
              >2</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(3)"
              >3</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setEquals"
              >=</v-btn
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="setAmount(0)"
              >0</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="doubleZero"
              >00</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="closeItem"
              >Annul.</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100px"
              color="accent"
              tile
              @click="updateOrderItem"
              >OK</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {errorHandler, fetchGET, fetchPOST, successHandler, toCurrency} from "../js/functions.js"
import { printOrderLabel } from '../js/pdfController.js'
export default {
  name: "editItemModal",
  props: {
    order: Object,
    editItemModal: Boolean,
    item: Object,
    itemCopy: Object,
    saveFixedPrices: Boolean,
    fixedPrices: Array,
    client: Object,
    newOrder: Boolean
  },
  data() {
    return {
      thisItem: JSON.parse(JSON.stringify(this.item)),
      thisOrder: this.order,
      thisFixedPrices: this.fixedPrices,
      setprice: false,
      setamount: false,
      settotal: false,
      setdot: false,
      dotindex: -1,
      dotvalue: "",
      subtotal: 0,
      setMinus: false,
      labelCopies: 0,
      saveFixedPrice: false,
      wholeNumbers: false,

    }
  },
  methods: {
    toCurrency(n) {
      return toCurrency(n)
    },
    setAmount(a) {
      if (!this.setamount && !this.setprice) {
        this.thisItem.amount = 0
        this.setamount = true
      }
      if (this.setprice) {
        this.thisItem.price = this.thisItem.price * 10 + a / 100
        this.thisItem.price = Math.round(this.thisItem.price * 100) / 100
      } else {
        if(this.wholeNumbers) {
          this.thisItem.amount = this.thisItem.amount * 10 + a
        }
        else{
        this.thisItem.amount = this.thisItem.amount * 10 + a / 100
        this.thisItem.amount = Math.round(this.thisItem.amount * 100) / 100
        }
      }
    },
    setPrice() {
      if (!this.setprice) {
        this.thisItem.price = 0
        this.setprice = true
      } else {
        this.setprice = false
      }
    },
    async deleteOrderItem(ids) {
     this.$emit('delete-order-item', ids)
      this.closeItem()
    },
    delOne() {
      let input = "amount"
      if (this.setprice) {
        input = "price"
      }
      let float = parseFloat(this.thisItem[input])
      let lastC = float.toFixed(2).substr(-1, 2)
      let last = parseInt(lastC) / 100
      let amount = float - last
      console.log(amount)
      if (amount > 0) {
        amount /= 10
        this.thisItem[input] = amount.toFixed(2)
      } else {
        this.clearAll(input)
      }
    },
    addTotal() {
      if (this.thisItem.amount == 0) this.setMinus = true
      if (this.setMinus) this.subtotal -= parseFloat(this.thisItem.amount)
      else this.subtotal += parseFloat(this.thisItem.amount)
      this.thisItem.amount = 0
      this.settotal = true
    },
    setEquals() {
      if (this.setMinus) this.subtotal -= parseFloat(this.thisItem.amount)
      else this.subtotal += parseFloat(this.thisItem.amount)
      this.setamount = false
      this.thisItem.amount = parseFloat(this.subtotal.toFixed(2))
      this.subtotal = 0
      this.settotal = false
      this.setMinus = false
    },
    clearAll() {
      let input = this.setprice ? "price" : "amount"
      this.thisItem[input] = 0
      this.dotvalue = ""
    },
    doubleZero() {
      if (this.setprice) {
        this.thisItem.price *= 100
        this.thisItem.price = parseFloat(this.thisItem.price.toFixed(2))
      } else {
        this.thisItem.amount *= 100
        this.thisItem.amount = parseFloat(this.thisItem.amount.toFixed(2))
      }
    },
    updateOrderItem() {
      if (this.settotal) {
        alert("Nog steeds in optelmodus,druk op =")
      } else {
        if (this.saveFixedPrice) {
          let noDuplicates = this.checkForDuplicates()
          if (noDuplicates) this.insertFixedPrice()
          else this.updateFixedPrice()
        }
        let item = JSON.parse(JSON.stringify(this.item))
        this.thisItem.status = 2
        this.$emit("update-order-item", this.thisItem, item)
        if(this.labelCopies) this.printOrderLabel(this.thisItem)
      }
    },
    findOrder(id) {
      for (let order of this.$store.state.orders) {
        if (order._id === id) {
          return order
        }
      }
    },
    async printOrderLabel(item) {
      let order = this.findOrder(item.order_id)
      let filter = JSON.parse(JSON.stringify(order))
        filter.items = [item]
        filter.itemIds = [item._id]
        this.$store.commit("orderLabelPrinted", filter)
        let buffer = await printOrderLabel(filter)
        let res = await fetchPOST("printOrderLabel", {buffer: buffer, copies: this.labelCopies})
        let res2 = await fetchPOST("orderLabelPrinted", filter)
        console.log(res)
        console.log(res2)
        // let leftFilter = JSON.parse(JSON.stringify(order))
        // let left = leftFilter.items.filter(item => item.status < 3)
        // console.log(left.length)
        // if (left.length == 0) {
        //   let obj = this.findOrder(order._id)
        //   this.orderStatus(obj)
        //   console.log("orderstatus")
        // }
  },
    incLabelCopies() {
      this.labelCopies++
    },
    cancelItem() {
      this.$emit("close-item")
    },
    closeItem() {
      if (this.settotal) {
        alert("Nog steeds in optelmodus,druk op =")
      } else {
        this.$emit("close-item")
        this.setprice = false
        this.setamount = false
      }
    },
    checkForDuplicates() {
      let index = this.thisFixedPrices.findIndex(
        item => item.itemNumber == this.thisItem.itemNumber
      )
      if (index > -1) return false
      return true
    },
    insertFixedPrice() {
      console.log("insertFixedPrice")// TODO: fix this
      let val = {
        clientName: this.client.name,
        itemNumber: this.thisItem.itemNumber,
        clientNumber: this.client.clientNumber,
        price: this.thisItem.price,
      }
      this.$store.commit("insertFixedPrice", val)
      this.$store.state.ws.send(
        JSON.stringify({ event: "insertFixedPrice", data: val })
      )
      successHandler("Prijsafspraak opgeslagen", 1000)
    },
    async updateFixedPrice() {
     try {
       let val = {
        clientName: this.client.name,
        itemNumber: this.thisItem.itemNumber,
        clientNumber: this.client.clientNumber,
        price: this.thisItem.price,
      }
      let res = await fetchPOST("updateFixedPrice",val)
      console.log(res)
      if(res.result.nModified==0) throw "updateFixedPrice"
      successHandler("Prijsafspraak opgeslagen")
      this.$store.commit("updateFixedPrice", val)
     } catch (e) {
       errorHandler(e,"Prijsafspraak niet opgeslagen")
     }
    },
    setWholeNumbers(){
      localStorage.setItem("wholeNumbers",JSON.stringify(this.wholeNumbers))
    }
  },
  async mounted() {
   if(this.fixedPrices===undefined){
     this.thisFixedPrices = await fetchGET("clientFixedPrices",this.thisOrder.clientNumber)
   }
   if(localStorage.getItem("wholeNumbers")!==null){
     this.wholeNumbers = JSON.parse(localStorage.getItem("wholeNumbers"))
   }
  },
}
</script>
<style scoped>
#delete {
  float: left;
  width: 25px;
}
td {
  padding: 0px;
}
#zero {
  width: 152px;
}
#amount {
  font-size: 2em;
}
#unit {
  font-size: 0.6em;
}
#price {
  font-size: 1.3em;
}
#dot {
  font-size: 2em;
  vertical-align: center;
}
.activePrice {
  background: #9ec739;
  color: white;
}
#incLabelCopies {
  float: right;
  width: 30px;
}
#labelCopies {
  float: right;
  font-size: 1.5em;
  color: black;
}
</style>
