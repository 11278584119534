<template>
  <!-- <v-container fluid> -->
  <div class="grid-container">
    <div class="header">
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col cols="12" sm="2">
              <v-menu
                ref="datePicker"
                v-model="datePicker"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFormatted"
                    @blur="date = parseDate(dateFormatted)"
                    label="Datum"
                    prepend-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  locale="nl"
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="datePicker = false"
                    >Annul.</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.datePicker.save(date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <search-supplier
                :searchString="searchString"
                @setSearchString ="setSearchString"
                @get-results="getSupplier"
                @clear-input="clearSupplier"
              ></search-supplier>
            </v-col>
                <v-col>Te factureren: {{ toCurrency(orderDebit) }}</v-col>
                <v-col style="color:red">Openstaand: {{ toCurrency(invoiceDebit) }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="7">
          <v-btn-toggle class="ma-2 white--text">
            <v-btn color="primary" @click="selectCategory('Recent')">
              <h3>Recent</h3>
            </v-btn>
            <v-btn
              color="primary"
              @click="selectCategory(category)"
              v-for="category in this.$store.state.settings.categories"
              :key="category"
            >
              <h3>{{ category }}</h3>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="5">
          <v-btn-toggle class="ma-2 white--text">
            <v-btn color="primary" @click="openPrintOrderModal"
              >Pakbon</v-btn
            >
            <v-btn color="primary" @click="openPrintInvoiceModal"
              >Factuur</v-btn
            >
            <v-btn
              color="primary"
              :disabled="order._id?true:false"
              @keydown.enter="insertPurchaseOrder"
              @click="insertPurchaseOrder"
              >Opslaan</v-btn
            >
            <v-btn color="primary" @click="newOrder(true)">Nieuw</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            height="20"
            label="Artikel"
            v-model="searchItems"
            @click="searchItems = ''"
          ></v-text-field>
        </v-col>
        <v-col cols="6"><v-text-field
        v-model="order.notes"
        label="Opmerking"
        height="20"
        ></v-text-field></v-col>
      </v-row>
    </div>
    <v-card tile class="summary">
      <div class="d-flex flex-column">
        <v-card flat class="v-card-color">
        </v-card>
        <v-card>
          <v-data-table
            dense
            :headers="orderItemHeaders"
            :items="orderItems"
            item-key="itemNumber"
            disable-pagination
            disable-sort
            fixed-header
            hide-default-footer
            id="data-table"
            class="data-table"
            calculate-widths
          >
            <template v-slot:[`item.name`]="{ item }">
              <b @click="deleteOrderItem(item)" :id="'#' + item.pos">{{
                item.name
              }}</b>
            </template>
            <template v-slot:[`item.amount`]="{ item }"
              >{{ item.amount }}{{ item.unit }}</template
            >
            <template v-slot:[`item.price`]="{ item }">
              <span :class="{'styleFixedPrice':item.fixed,'styleSalePrice':item.sale}">{{toCurrency(item.price)}}</span>
            </template>
            <template
              v-if="this.selectedSupplier.includeVAT"
              v-slot:[`item.netSubtotal`]="{ item }"
              >{{ toCurrency(item.grossSubtotal) }}</template
            >
            <template v-else v-slot:[`item.netSubtotal`]="{ item }">{{
              toCurrency(item.netSubtotal)
            }}</template>
          </v-data-table>
        </v-card>
        <v-card tile class="summaryTotals">
          <v-list dense>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Aantal:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                orderItems.length
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Collis:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                totalPackages
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content class="font-weight-bold"
                >Te betalen:</v-list-item-content
              >
              <v-list-item-content class="align-end font-weight-bold">{{
                toCurrency(grossTotal)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Excl. btw:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                toCurrency(netTotal)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item
              class="vlistitem"
              dense
              v-for="vat in VAT"
              :key="vat.name"
            >
              <v-list-item-content
                >{{ vat.name }} {{ vat.amount }}%:</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                toCurrency(vat.total)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Totaal btw:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                toCurrency(totalVAT)
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-row no-gutters>
          <v-col>
            <v-btn
              @click="openSupplierModal"
              block
              color="primary"
              :disabled="!supplier._id"
              >Leverancier</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-row class="items" no-gutters>
      <v-col class="mt-5 pa-0" cols="12">
        <ul class="Items" v-show="!success&&activeSupplier">
          <li v-for="(item, index) in categories" :key="item.itemNumber">
            <v-row>
              <v-col cols="4">
                <h3>
                  <a @click="openItemModal(item)">{{ item.name }}</a>
                </h3>
              </v-col>
              <v-col cols="2" class="text-right pa-0 ml-1">
                <!-- <v-btn
                  height="45"
                  outlined
                  @click="deleteOrderItem(item)"
                  class="amount"
                >
                  {{ item.amount }}
                  <span
                    style="font-size:15px;text-transform:lowercase;margin-bottom:-10px"
                    >{{ item.unit }}</span
                  >
                </v-btn> -->
                <button @click="deleteOrderItem(item)"
                  class="amount">{{ item.amount }}
                  <span
                    style="font-size:15px;text-transform:lowercase;margin-bottom:-10px"
                    >{{ item.unit }}</span></button>
              </v-col>
              <v-col cols="4" class="pa-0 ml-1">
                <!-- <v-btn-toggle
                  v-if="setAmounts && $vuetify.breakpoint.name != 'xs'"
                  class="white--text"
                >
                  <v-btn
                    color="primary"
                    depressed
                    :disabled="!$store.state.wsConnected"
                    v-for="amount in setAmounts"
                    :key="amount"
                    @click="setAmount(index, amount)"
                    >{{ amount }}</v-btn
                  >
                </v-btn-toggle> -->
                <div class="btn-group">
  <button v-for="amount in setAmounts"
                    :key="amount"
                    @click="setAmount(index, amount)"
                    >{{ amount }}</button>

</div>
              </v-col>
            </v-row>
          </li>
        </ul>
        <h1 v-if="!activeSupplier">Leverancier is geblokkeerd door administratie.</h1>
      </v-col>
    </v-row>
    <!-- 
    Notes
    -->
    <edit-item-modal
      v-if="editItemModal"
      class="modal-container"
      :item="item"
      :editItemModal="editItemModal"
      :saveFixedPrices="true"
      :fixedPrices="fixedPrices"
      :supplier="supplier"
      @update-order-item="updateOrderItem"
      @cancel-edit-item-modal="cancelItemModal"
      @close-item="closeItemModal"
    ></edit-item-modal>
    <supplier-modal
      v-if="supplierModal"
      :tabNumber="2"
      :supplier="supplier"
      :supplierModal="supplierModal"
      @insert-supplier="updateSupplier"
      @close-supplier-modal="supplierModal = false"
    ></supplier-modal>
    <print-order-modal
      v-if="printOrderModal"
      :printOrderModal="printOrderModal"
      :order="order"
      @close-print-order-modal="closePrintOrderModal"
    ></print-order-modal>
    <insert-invoice-modal
      v-if="insertInvoiceModal"
      printer="invoicePrinter"
      :insertInvoiceModal="insertInvoiceModal"
      :order="order"
      :invoice="invoice"
      :singleOrder="true"
      :openOrders="openOrders"
      :type="printType"
      :newOrder="true"
      @add-invoice-payments="openInvoicePaymentModal"
      @close-insert-invoice-modal="closeInsertInvoiceModal"
    ></insert-invoice-modal>
    <invoice-payment-modal
        v-if="invoicePaymentModal"
        :invoicePaymentModal="invoicePaymentModal"
        :invoice="invoice"
        @insert-payments="insertInvoicePayments"
        @close-payment-modal="closeInvoicePaymentModal"
      ></invoice-payment-modal>
  </div>
  <!-- </v-container> -->
</template>
<script>
/* eslint-env es6 */
import searchSupplier from "../components/searchSupplier"
import editItemModal from "../components/editItemModal"
import invoicePaymentModal from "../components/invoicePaymentModal"
import supplierModal from "../components/supplierModal"
import printOrderModal from "../components/printOrderModal"
import insertInvoiceModal from "../components/insertInvoiceModal"
import { groupBy,errorHandler, getWeekNumber, fetchGET, fetchPOST,toCurrency, successHandler } from "../js/functions.js"

import { mapState, mapGetters } from "vuex"
import Big from 'big.js';
Array.prototype.groupBy = groupBy
export default {
  name: "newOrder",
  data() {
    return {
      datePicker: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0],
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0],
      dateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      supplier: { city: " " },
      activeSupplier: true,
      category: "",
      items: [{sort:0},{sort:1}],
      order: {},
      invoice: {},
      fixedPrices: [],
      salePrices: [],
      supplierOrders: [],
      supplierInvoices: [],
      recent: [],
      shortcuts: ["Afhalen", "Ochtend", "Middag 1", "Middag 2"],
      orderItemHeaders: [
        { text: "Artikel", value: "name" },
        { text: "Aantal", value: "amount"},
        { text: "Prijs", value: "price" },
        { text: "Subtotaal", value: "netSubtotal"}
      ],
      printOrderModal: false,
      insertInvoiceModal: false,
      printType: "",
      route: "",
      order_id: "",
      orderNotes: "",
      editItemModal: false,
      collectOrders: false,
      searchSupplier: true,
      searchItems: "",
      newRoute: false,
      editRoute: false,
      orderItems: [],
      item: { amount: 0 },
      success: false,
      payments: [],
      invoicePaymentModal: false,
      supplierModal: false,
      routeDetails: false,
      searchString: ""
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    openSupplierModal(){
      this.supplierModal = true
    },
    openInvoicePaymentModal(invoice){
      this.invoice = invoice
      this.invoicePaymentModal = true
    },
    closeInvoicePaymentModal(){
      this.invoicePaymentModal = false
      this.invoice = {}      
    },
    async getSupplier(obj) {
      this.supplier = obj
      this.success = false
      this.searchItems = ""
      this.activeSupplier = obj.active
      this.$store.commit("selectSupplier", obj)
      this.order.route = this.selectedSupplier.defaultRoute ? this.selectedSupplier.defaultRoute : ""
      this.setSupplier(obj)
      this.searchSupplier = false
    },
    changeDate(e) {
      if (e.target.valueAsDate != null) {
        let d = e.target.valueAsDate
        let date = new Date(d)
        let isoDate = date.toISOString().split("T")[0]
        let ws = this.$store.state.ws
        ws.send(
          JSON.stringify({
            event: "fetchOrders",
            data: { supplierNumber: false, fromDate: isoDate, toDate: isoDate }
          })
        )
      }
    },
    async setSupplier() {
    //   let supplierData = await fetchGET("supplierData",supplier.supplierNumber)
    //   let fixedPrices = supplierData[0].value
    //   this.supplierOrders=  supplierData[1].value
    //   this.supplierInvoices = supplierData[2].value
    //   let recentOrders = supplierData[3].value
    //   recentOrders.forEach(order=>order.items.forEach(item=>this.recent.push(item.itemNumber)))
    //   if(this.recent.length)this.selectCategory("Recent")
    //   this.fixedPrices = fixedPrices
    //   let date = new Date(this.date)
    //   for (let item of this.items) {
    //     item.fixed = false
    //     item.sale = false
    //     item.price = parseFloat(item.inPrice)
    //     let fixedIndex= fixedPrices.findIndex(fix=>fix.itemNumber==item.itemNumber)
    //     if(fixedIndex>-1){
    //         item.fixed = true
    //         item.price = fixedPrices[fixedIndex].price
    //         if(new Date(fixedPrices[fixedIndex].fromDate)<=date&&new Date(fixedPrices[fixedIndex].toDate)>=date){
    //           item.sale = true
    //           item.price = fixedPrices[fixedIndex].salePrice
    //         }
    //       }
//       let amount = Big(item.amount)
//       let price = Big(item.price)
//       let unitAmount = Big(item.unitAmount)
//       let packages = Big()
//       let totalWeight = Big()
//       let netSubtotal = Big()
//       let totalVAT = Big()
//       let grossSubtotal = Big()
//       item.VAT = this.selectedSupplier.export? 0: item.VAT
//       let itemVAT = Big(item.VAT).div(100)
//       packages = item.unit == "kg"? amount.div(item.packageAmount).round(undefined,3): amount
//       totalWeight = item.unit == "kg"? amount: amount.times(item.unitAmount).round(2)
//      netSubtotal = item.unit == "kg"
//           ? amount.times(price).round(2)
//           : amount.times(price).times(unitAmount).round(2)
//           if (supplier.includeVAT)netSubtotal = netSubtotal.div(Big(item.VAT).plus(100).div(100))
//       totalVAT = netSubtotal.times(itemVAT)
//       grossSubtotal = netSubtotal.plus(totalVAT)
//       item.packages = packages.toNumber()
//       item.totalWeight = totalWeight.toNumber()
// item.netSubtotal = netSubtotal.toNumber()
// item.totalVAT = totalVAT.toNumber()
// item.grossSubtotal = grossSubtotal.toNumber()
//         item.totalCost = item.unit == "kg"
//             ? parseFloat(item.amount) * parseFloat(item.cost)
//             : parseFloat(item.amount) *
//               (parseFloat(item.unitAmount) * parseFloat(item.cost))
//         item.totalCost = Math.round(item.totalCost * 100) / 100
//         item.totalProfit =
//           parseFloat(item.netSubtotal) - parseFloat(item.totalCost)
//           let d= new Date(Date.now()).toISOString().split("T")
//           d = d[0].split("-")
//           let lotNumber = `${d[0].substr(-2)}/${d[1]}${d[2]}`
//           item.lotNumber = lotNumber
//       }
    },
    setSearchString(val){
      this.searchString = val
    },
    updateSupplier(supplier) {
      try {
      this.$store.commit("updateSupplier", supplier)
      this.$store.commit("selectSupplier", supplier)
      this.$store.state.ws.send(
        JSON.stringify({ event: "updateSupplier", data: supplier })
      )
      this.supplierModal = false
      successHandler( "Leverancier gewijzigd")
      }
      catch(e){
        errorHandler(e,"Leverancier niet gewijzigd")
      }
    },
    clearSupplier() {
      let confirmed
      if (this.orderItems.length && this.selectedSupplier._id && !this.success) {
        confirmed = confirm("Huidige order annuleren?")
      } else confirmed = true
      if (confirmed) {
        this.searchSupplier = true
        this.$store.commit("selectSupplier", {})
        this.newOrder()
        this.searchString = ""
      } else return false
    },
    async setAmount(index, plus) {
      // if (parseFloat(this.categories[index].price) < parseFloat(this.categories[index].inPrice)) this.categories[index].fixedPrice = true
      let reservedItem = JSON.parse(JSON.stringify(this.categories[index]))
      let amount = Big(this.categories[index].amount)
      let price = Big(this.categories[index].price)
      let unitAmount = Big(this.categories[index].unitAmount)
      let packages = Big()
      let totalWeight = Big()
      let netSubtotal = Big()
      let totalVAT = Big()
      let grossSubtotal = Big()      
      this.categories[index].VAT = this.selectedSupplier.export?0: this.categories[index].VAT
      let itemVAT = Big(this.categories[index].VAT).div(100)
      
      // let itemVATincluded = Big((100+this.categories[index].VAT).div(100))
      amount = amount.plus(plus)
      this.categories[index].amount = amount.toNumber()
     packages = this.categories[index].unit == "kg"? amount.div(this.categories[index].packageAmount).round(undefined,3): amount
     totalWeight = this.categories[index].unit == "kg"? amount: amount.times(this.categories[index].unitAmount).round(2)
     netSubtotal = this.categories[index].unit == "kg"
          ? amount.times(price).round(2)
          : amount.times(price).times(unitAmount).round(2)
          if (this.selectedSupplier.includeVAT)netSubtotal = netSubtotal.div((Big(this.categories[index].VAT).plus(100)).div(100))
      totalVAT = netSubtotal.times(itemVAT)
      grossSubtotal = netSubtotal.plus(totalVAT)
this.categories[index].packages = packages.toNumber()
this.categories[index].totalWeight = totalWeight.toNumber()
this.categories[index].netSubtotal = netSubtotal.toNumber()
this.categories[index].totalVAT = totalVAT.toNumber()
this.categories[index].grossSubtotal = grossSubtotal.toNumber()

        
        // console.log(this.categories[index].packages)
      this.categories[index].totalCost =
        this.categories[index].unit == "kg"
          ? parseFloat(this.categories[index].amount) *
            parseFloat(this.categories[index].cost)
          : parseFloat(this.categories[index].amount) *
            (parseFloat(this.categories[index].unitAmount) *
              parseFloat(this.categories[index].cost))
      this.categories[index].totalCost =
        Math.round(this.categories[index].totalCost * 100) / 100
      this.categories[index].totalProfit =
        this.categories[index].netSubtotal - this.categories[index].totalCost
      let itemIndex = this.orderItems.findIndex(
        item => item._id == this.categories[index]._id
      )
      if (itemIndex > -1) {
        this.categories[index] = this.orderItems[itemIndex]
      }
      if (itemIndex === -1) {
        this.categories[index].pos = this.orderItems.length || 0
        this.orderItems.push(this.categories[index])
      }
      this.categories
      let orderItems = this.orderItems
      let length = orderItems.length - 1
      // let update = reservedItem.amount != 0 ? true : false
      reservedItem.amount = amount
      // this.$store.state.ws.send(
      //   JSON.stringify({
      //     event: "reserveItem",
      //     data: { item: reservedItem, update: update, success: false }
      //   })
      // )
      setTimeout(function() {
        if (orderItems && orderItems.length) {
          let element = document.getElementById("#" + length)
          element.scrollIntoView(false)
        }
      }, 500)
      
    },
    selectCategory(cat) {
      if (this.success) this.newOrder()
      this.searchItems = ""
      this.category = cat
    },
    orderExists() {
      let orders = this.$store.state.orders
      for (let order of orders) {
        if (order.supplier._id === this.selectedSupplier._id && order.status < 2&&order.invoiceNumber==null) {
          return order
        }
      }
      return false
    },
    insertOrderPayments(payments) {
      delete this.order._id
      this.order.payments = payments
      this.payments = payments
      this.paymentModal = false
    },
    insertInvoicePayments(payments) {
      this.invoice.payments = payments
      let totalPaid = payments.reduce((acc, cur) => (acc*100 + parseFloat(cur.amount)*100)/100, 0)
      this.invoice.credit = totalPaid
      this.invoice.debit = (this.invoice.grossTotal*100 - totalPaid*100)/100
      this.invoice.status = this.invoice.debit == 0 ? 2 : 1
      this.updateInvoice(this.invoice)
      this.paymentModal = false
      this.success = true
      this.closeInvoicePaymentModal()
    },
    closePaymentModal(){
      delete this.order._id
      this.paymentModal = false
    },
    updateInvoice(invoice) {
      try {
          this.$store.commit("updateInvoice", invoice)
          let ws = this.$store.state.ws
          ws.send(JSON.stringify({ event: "updateInvoice", data: invoice }))
          successHandler("Factuur gewijzigd")
      } catch (e) {
        errorHandler(e,"Factuur niet gewijzigd")
      }
    },
    async newOrder(force) {
      if (this.success || force) {
        this.orderItems = []
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0]
        this.order = {}
        this.searchString = ""
        this.$store.commit("selectSupplier", {})
      }
      let response = await fetchGET("fetchItems",{options:{page:0,itemsPerPage:0,sortBy:[],sortDesc:[]}})
      this.items = response[0].results
      this.order = {
        assigned: "",
        payments: [],
        status: 0,
        credit: 0,
        debit: 0,
        route: ""
      }
      this.recent = []
      this.orderNotes = ""
      this.searchSupplier = true
      this.searchItems = ""
      this.newRoute = false
      this.editRoute = false     
      this.success = false
    },
    async deleteOrderItem(val) {
      let reservedItem = JSON.parse(JSON.stringify(val))
      let index = this.orderItems.findIndex(item => item._id == val._id)
      if (index > -1) {
        this.orderItems.splice(index, 1)
        reservedItem.amount = -val.amount
        // this.$store.state.ws.send(
        //   JSON.stringify({
        //     event: "reserveItem",
        //     data: { item: reservedItem, update: true, success: false }
        //   })
        // )
        val.amount = 0
        val.netSubtotal = 0
       
      }
    },
    updateOrderItem(val, oldVal) {
      let amount = Big(val.amount)
      let price = Big(val.price)
      let unitAmount = Big(val.unitAmount)
      let packages = Big()
      let totalWeight = Big()
      let netSubtotal = Big()
      let totalVAT = Big()
      let grossSubtotal = Big()
      val.VAT = this.selectedSupplier.export? 0: val.VAT
      let itemVAT = Big(val.VAT).div(100)
      packages = val.unit == "kg"? amount.div(val.packageAmount).round(undefined,3): amount
      totalWeight = val.unit == "kg"? amount: amount.times(val.unitAmount).round(2)
     netSubtotal = val.unit == "kg"
          ? amount.times(price)
          : amount.times(price).times(unitAmount)
          if (this.selectedSupplier.includeVAT)netSubtotal = netSubtotal.div(Big(val.VAT).plus(100).div(100))
          console.log(netSubtotal.toNumber())
      totalVAT = netSubtotal.times(itemVAT)
      grossSubtotal = netSubtotal.plus(totalVAT)
      val.packages = packages.toNumber()
      val.totalWeight = totalWeight.toNumber()
val.netSubtotal = netSubtotal.toNumber()
val.totalVAT = totalVAT.toNumber()
val.grossSubtotal = grossSubtotal.toNumber()
      val.totalCost =
        val.unit == "kg"
          ? parseFloat(val.amount) * parseFloat(val.cost)
          : parseFloat(val.amount) *
            (parseFloat(val.unitAmount) * parseFloat(val.cost))
      val.totalCost = Math.round(val.totalCost * 100) / 100
      val.totalProfit = parseFloat(val.netSubtotal) - parseFloat(val.totalCost)
      let reservedItem = JSON.parse(JSON.stringify(val))
      reservedItem.amount = reservedItem.amount - oldVal.amount
      this.item.name = val.name
      this.item.amount = val.amount
      this.item.price = val.price
      this.item.fixed = val.fixed
      this.item.netSubtotal = val.netSubtotal
      this.item.grossSubtotal = val.grossSubtotal
      this.item.totalVAT = val.totalVAT
      this.item.totalProfit = val.totalProfit
      this.item.totalCost = val.totalCost
      this.item.VAT = val.VAT
      let itemIndex = this.orderItems.findIndex(item => item._id == val._id)
      if (itemIndex > -1) {
        if (parseInt(val.amount) == 0) {
          this.orderItems.splice(itemIndex, 1)
        }
      }
      if (itemIndex == -1) {
        this.item.pos = this.orderItems.length || 0
        this.orderItems.push(this.item)
      }

      // let update = oldVal.amount == 0 ? false : true
      // this.$store.state.ws.send(
      //   JSON.stringify({
      //     event: "reserveItem",
      //     data: { item: reservedItem, update: update, success: false }
      //   })
      // )
     
      this.editItemModal = false
    },
    openItemModal(item) {
      if (!e) var e = window.event
      e.cancelBubble = true
      if (e.stopPropagation) e.stopPropagation()
      this.item = item
      this.editItemModal = true
    },
    closeItemModal() {
      this.editItemModal = false
    },
    cancelItemModal() {
      this.item = this.item.amount = 0
      this.editItemModal = false
    },
    createOrder(direct, invoice, update) {
      try {
        let order = JSON.parse(JSON.stringify(this.order))
        order.supplierNumber = this.selectedSupplier.supplierNumber
        order.supplier = {
          _id: this.selectedSupplier._id,
          supplierNumber: this.selectedSupplier.supplierNumber,
          active: this.selectedSupplier.active,
          name: this.selectedSupplier.name,
          address: this.selectedSupplier.address,
          postalcode: this.selectedSupplier.postalcode,
          city: this.selectedSupplier.city,
          country: this.selectedSupplier.country,
          phone: this.selectedSupplier.phone,
          mobile: this.selectedSupplier.mobile,
          email: this.selectedSupplier.email,
          contact: this.selectedSupplier.contact,
          includeVAT: this.selectedSupplier.includeVAT,
          export: this.selectedSupplier.export,
          exportEU: this.selectedSupplier.exportEU,
        }
        if (invoice) order.invoiceNumber = 0
        order.status = 0
        if (direct) order.status = 2
        let itemExists = false
        let orderItems
        if (!update) {
          orderItems = JSON.parse(JSON.stringify(this.orderItems))
          order.items = []
          for (let item of orderItems) {
            item.inAmount = item.amount
            item.inUnit = item.unit
            item.status = 0
            item.assigned = ""
            if (order.assigned || direct) item.status = 1
            order.items.push(item)
          }
        } else {
          orderItems = JSON.parse(JSON.stringify(order.items))
          for (let item of this.orderItems) {
            for (let orderItem of orderItems) {
              if (
                orderItem.itemNumber == item.itemNumber &&
                orderItem.unit == item.unit
              ) {
      let amount = Big(orderItem.amount)
      let packages = Big(orderItem.packages)
      let totalWeight = Big(orderItem.totalWeight)
      let netSubtotal = Big(orderItem.netSubtotal)
      let totalVAT = Big(orderItem.totalVAT)
      let grossSubtotal = Big(orderItem.grossSubtotal)
                orderItem.amount =
                  amount.plus(item.amount)
                  orderItem.packages =
                  packages.plus(item.packages)
                  orderItem.totalWeight=
                  totalWeight.plus(item.totalWeight)
                orderItem.netSubtotal =
                  netSubtotal.plus(item.netSubtotal)
                orderItem.grossSubtotal =
                 grossSubtotal.plus(item.grossSubtotal)
                orderItem.totalVAT =
                  totalVAT.plus(item.totalVAT)
                orderItem.totalCost =
                  parseFloat(orderItem.totalCost) + parseFloat(item.totalCost)
                orderItem.totalProfit =
                  parseFloat(orderItem.totalProfit) +
                  parseFloat(item.totalProfit)
                orderItem.status = 0
                orderItem.assigned = ""
                if (order.assigned || direct) orderItem.status = 1
                itemExists = true
              }
            }
            if (!itemExists) {
              item.inAmount = item.amount
              item.inUnit = item.unit
              item.status = 0
              item.assigned = ""
              if (order.assigned) item.status = 1
              if (direct) item.status = 2
              orderItems.push(item)
            }
            itemExists = false
          }
        }
        orderItems = orderItems.sort((a, b) => a.sort - b.sort)
        order.totalProfit = orderItems.reduce(
          (acc, cur) => acc + cur.totalProfit,
          0
        )
        order.totalCost = orderItems.reduce(
          (acc, cur) => acc + cur.totalCost,
          0
        )
        order.name = this.selectedSupplier.name
        if (!update) {
          order.totalPackages = this.totalPackages
          order.totalWeight = this.totalWeight
          order.netTotal = this.netTotal
          order.grossTotal = this.grossTotal
        } else {
          order.totalPackages = (order.totalPackages*100 + this.totalPackages*100)/100
          order.totalWeight = (order.totalWeight*100 + this.totalWeight*100)/100
          order.netTotal = (order.netTotal*100 + this.netTotal*100)/100
          order.grossTotal =  (order.grossTotal*100 + this.grossTotal*100)/100
        }
        let orderVAT
        if(this.selectedSupplier.export){
          orderVAT = [{name: "Geen", amount: 0, total: order.netTotal}]
        }
        else{ 
          orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
        for (let VAT of orderVAT) {
          VAT.total = orderItems
            .filter(item => VAT.amount === item.VAT)
            .reduce((acc, cur) => (acc*100 + parseFloat(cur.totalVAT)*100)/100, 0)
        }
        }
        let VAT = orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
        order.VAT = VAT
        order.totalVAT = orderItems
          .filter(item => item.VAT > 0)
          .reduce((acc, cur) => (acc*100 + cur.totalVAT*100)/100, 0)
        let totalPaid = 0
        if (order.payments.length) {
           totalPaid = order.payments.reduce((acc,cur)=> (acc*100 + parseFloat(cur.amount)*100)/100,0)
        }
        if (order.assigned) order.status = 1
        order.items = orderItems
        order.credit = totalPaid
        order.debit = update
          ? (order.grossTotal*100 - totalPaid*100)/100
          : (this.grossTotal*100 - totalPaid*100)/100
        if (order.debit == 0) order.status = 3
        order.printed = false
        order.emailed = false
        order.emailStatus = ""
        if (this.orderNotes) order.notes = this.orderNotes
        order.packages = 0
        order.date = this.date
        let date = new Date(this.date)
        order.day = date.getDate()
        order.month = date.getMonth() + 1
        order.year = date.getFullYear()
        order.week = getWeekNumber(date)
        order.quarter = Math.ceil(order.month / 3)
        order.expand = false
        order.selectable = true
        return order
      } catch (e) {
        console.log(e)
      }
    },
    async insertPurchaseOrder() {
      try{
      if (
        this.selectedSupplier._id &&
        this.orderItems.length
      ) {
          let order = this.createOrder(false, false, false)
          let event = "insertPurchaseOrder"
        this.success = true
        let res = await fetchPOST(event,order)
        order = res.ops[0]
        this.$store.commit("insertPurchaseOrder",order)
        successHandler("Order opgeslagen")
        return order
      } else {
        let message = ""
        if (!this.selectedSupplier._id) message += "-Selecteer een leverancier"
        if (!this.orderItems.length) message += "\n-Selecteer een item"
        alert(message)
      }
      }
      catch(e){
        errorHandler(e,"Order niet opgeslagen")
      }
    },
    async openPrintOrderModal() {
      if (
        this.selectedSupplier._id &&
        this.orderItems.length &&
        this.order.route !== ""
      ) {
        this.order = await this.insertPurchaseOrder()
        this.printOrderModal = true
      } else {
        let message = ""
        if (!this.selectedSupplier._id) message += "-Selecteer een leverancier"
        if (!this.orderItems.length) message += "\n-Selecteer een item"
        if (this.order.route == "") message += "\n-Selecteer een route"
        alert(message)
      }
    },
    openPrintInvoiceModal() {
      if (
        this.selectedSupplier._id &&
        this.orderItems.length &&
        this.order.route !== ""
      ) {
        this.order = this.createOrder(true)
        this.invoice.orders = []
        this.invoice.payments =[]
        this.invoice.supplier = this.selectedSupplier
        this.$store.state.ws.send(
        JSON.stringify({
          event: "openOrders",
          data: { supplierNumber: this.selectedSupplier.supplierNumber }
        })
      )
      this.printType = "newInvoice"
      this.insertInvoiceModal = true
      } else {
        let message = ""
        if (!this.selectedSupplier._id) message += "-Selecteer een leverancier"
        if (!this.orderItems.length) message += "\n-Selecteer een item"
        if (this.order.route == "") message += "\n-Selecteer een route"
        alert(message)
      }
    },
    closePrintOrderModal() {
      this.printOrderModal = false
    },
     closePrintInvoiceModal() {
      this.insertInvoiceModal = false
    },
    closeInsertInvoiceModal(){
    this.insertInvoiceModal = false
    },
    createInvoice() {
      console.log("create invoice")
      let orders = this.invoice.orders
      let d = new Date(this.date)
      let day = d.getDate()
      let week = getWeekNumber(d)
      let month = d.getMonth() + 1
      let year = d.getFullYear()
      let quarter = Math.ceil(month / 3)
      let date = new Date(this.date)
      let payments = this.invoice.orders
        .map(order => order.payments)
        .filter(payment => payment.length > 0)
        .flat()
      let totalPaid = payments.length
        ? payments.reduce((acc, cur) => (acc*100 + cur.amount*100)/100, 0)
        : 0
      let credit = totalPaid
      let netTotal = orders.reduce((acc, cur) => {
        return (acc*100 + cur.netTotal*100)/100
      }, 0)
      let totalVAT = orders.reduce((acc, cur) => {
        return (acc*100 + cur.totalVAT*100)/100
      }, 0)
      let grossTotal = orders.reduce((acc, cur) => {
        return (acc*100 + cur.grossTotal*100)/100
      }, 0)
      let debit = (grossTotal*100 - credit*100)/100
      let status = debit==0?2:1
      let payTerms = parseInt(this.selectedSupplier.payTerms) || 14
      let invoiceVAT
      if(this.selectedSupplier.export){
        invoiceVAT = [{name: "Geen", amount: 0, total: netTotal}]
      }
       else {
         invoiceVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
          for (let VAT of invoiceVAT) {
          VAT.total = orders.reduce((acc,cur)=>{
            return (acc*100 + cur.VAT.filter(vat=> vat.amount==VAT.amount).reduce((acc,cur2)=> (acc*100 + cur2.total*100)/100,0 )*100)/100
          },0)
        }  
       }
      invoiceVAT = invoiceVAT.filter(VAT => parseFloat(VAT.total) !== 0)
      d.setDate(date.getDate() + payTerms)
      let dueDate = new Date(d.toISOString().split("T")[0])
      let invoice = {
        supplier: this.selectedSupplier,
        supplierNumber: this.selectedSupplier.supplierNumber,
        name: this.selectedSupplier.name,
        orders: this.invoice.orders,
        date: this.date,
        dueDate: dueDate,
        day: day,
        week: week,
        month: month,
        year: year,
        quarter: quarter,
        netTotal: netTotal,
        totalVAT: totalVAT,
        grossTotal: grossTotal,
        credit: credit,
        debit: debit,
        payments: payments,
        totalPaid: totalPaid,
        VAT: invoiceVAT,
        status: status,
        printed: true,
        expand: false,
        type: "sales",
        notes: "",
        totalProfit: 0,
        totalCost: 0,
        discount: 0,
        totalDiscount: 0
      }
      return invoice
    },
    toCurrency(n) {
      return toCurrency(n)
    }
  },
  computed: {
      selectedSupplier(){
          return this.$store.state.selectedSupplier
      },
    categories() {
      console.log("categories")
      let items = this.items
      items = items.sort((a,b)=>a.sort-b.sort)
      let recent = this.recent
      let categories = items.groupBy("category", recent)
      if (this.searchItems) {
        return items.filter(
          item =>
            item.name.toLowerCase().indexOf(this.searchItems.toLowerCase()) > -1
        )
      }
      return categories[this.category]
    },
    totalPackages(){
      let totalPackages = this.orderItems.reduce(
        (acc, cur) => (acc*100 + parseFloat(cur.packages)*100)/100,
        0
      )
      return totalPackages
    },
    totalWeight(){
      let totalWeight = this.orderItems.reduce(
        (acc, cur) => (acc*100 + parseFloat(cur.totalWeight)*100)/100,
        0
      )
      return totalWeight
    },
    netTotal() {
      let netTotal = this.orderItems.reduce(
        (acc, cur) => (acc*100 + parseFloat(cur.netSubtotal)*100)/100,
        0
      )
      return netTotal
    },
    grossTotal() {
      let grossTotal = (parseFloat(this.netTotal)*100 + parseFloat(this.totalVAT)*100)/100
      return grossTotal
    },
    credit() {
      let credit =  this.order.payments.reduce(
        (acc, cur) => (acc*100 + parseFloat(cur.amount)*100)/100,
        0
      )
      return credit
    },
    debit() {
      return (this.grossTotal*100 - this.credit*100)/100
    },
    totalVAT() {
      let totalVAT = this.VAT.filter(vat => vat.amount > 0).reduce(
        (acc, cur) => (acc*100 + parseFloat(cur.total)*100)/100,
        0
      )
      return totalVAT
    },
    VAT() {
      let orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
      if(this.selectedSupplier.export){
        return [{name: "Geen", amount: 0, total: this.netTotal}]
      }
      for (let VAT of orderVAT) {
        VAT.total = this.orderItems
          .filter(item => VAT.amount === item.VAT)
          .reduce((acc, cur) => (acc*100 + parseFloat(cur.totalVAT)*100)/100, 0)
      }
      return orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
    },
    routes() {
      let routes = [...this.$store.state.settings.routes]
      return routes.sort()
    },
    docks() {
      return this.$store.state.settings.docks
    },
    ordersFilteredByRoute() {
      let orders = this.$store.state.orders.filter(
        order => order.route == this.order.route
      )
      return orders
    },
    setAmounts() {
      return this.$store.state.settings.setAmounts
    },
    orderDebit(){
    return this.supplierOrders.reduce((acc,cur)=>acc+cur.debit,0)
    },
    invoiceDebit(){
    return this.supplierInvoices.reduce((acc,cur)=>acc+cur.debit,0)
    },
    ...mapState(["active", "payMethods", "employees","openOrders"]),
    ...mapGetters(["allRoutes"])
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val)
      if (!this.success) {
        let ws = this.$store.state.ws
        ws.send(
          JSON.stringify({
            event: "fetchOrders",
            data: { supplierNumber: false, fromDate: val, toDate: val }
          })
        )
      }
    }
  },
  async mounted() {
    await this.newOrder()
    if(this.selectedSupplier.supplierNumber){
      this.searchString = this.selectedSupplier.name
this.getSupplier(this.selectedSupplier)
    }
  },
  // beforeDestroy() {
  //   this.$store.state.ws.send(
  //     JSON.stringify({ event: "undoReservedItems", data: this.orderItems })
  //   )
  // },
  components: {
    searchSupplier,
    editItemModal,
    invoicePaymentModal,
    supplierModal,
    printOrderModal,
    insertInvoiceModal
  }
}
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: 170px 1fr;
  grid-template-areas: "header summary" "items summary";
  position: relative;
  /* background: #69a9e4; */
}
.header {
  grid-area: header;
  position: sticky;
  top: 0;
  background: var(--v-container_background-base);
  z-index: 1;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: space-evenly;
  border: 1px solid #ccc;
  /* background: #69a9e4; */
  border-radius: 5px;
}
.orders-filtered-by-route {
  list-style: circle;
  height: 150px;
  overflow: auto;
}
.vlistitem {
  height: 20px;
  min-height: 20px;
}
.input-date-container {
  margin-left: 35px;
}
.categories-container {
  display: flex;
  flex-direction: row;
}
.select {
  background: white;
  width: 150px;
  height: 30px;
  font-size: 20px;
}
input {
  height: 30px;
  font-size: 1.2em;
}
#date {
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.summary {
  font-size: 14px;
  grid-area: summary;
  width: 300px;
  height: 100%;
  border-left: 1px solid lightgray;
  position: fixed;
  top: 55px;
  right: 1px;
  z-index: 2;
  display: inline-block;
  overflow-y: auto;
  /* background: #69a9e4; */
}
.summary-flex-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}
.v-card-color {
  /* background: #69a9e4; */
}
#route-radio span {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  text-align: center;
  border-radius: 35px;
  border: 3px solid black;
  display: inline-block;
}
#route-radio {
  /* background: #69a9e4; */
}
select.v-select-select {
  min-height: 100px !important;
  max-height: 100px !important;
  display: flex !important;
  align-items: center !important;
}
.selected-route {
  background: black;
  color: white;
}
#data-table,
#data-table table tr {
  border-radius: 0;
  /* background: #69a9e4; */
}
.v-data-table thead tr th {
  /* background: #69a9e4; */
}
.data-table .v-data-table table tr {
  /* background: #69a9e4; */
}
.btn-group button {
  background-color: blue; /* Green background */
  border: 1px solid blue; /* Green border */
  color: white; /* White text */
  padding: 10px 15px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}
.btn-group button:focus {
  outline: none;
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: darkblue;
}
.order-items {
  text-align: left;
  /* height: 390px; */
  text-align: left;
  overflow-y: scroll;
}
.styleFixedPrice{
  background: red;
  color: white;
}
.styleSalePrice{
  background: green;
  color: white;
}
.Items {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.Items li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media only screen and (max-width: 600px) {
  .summary {
    position: relative;
  }
  .Items {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.big-input {
  font-size: 20px;
}
.items {
  grid-area: items;
}
.summaryTotals {
  text-align: right;
}
ul {
  list-style: none;
}
.center {
  text-align: center;
}
#success {
  font-size: 2em;
  display: inline-block;
}
.categories {
  height: 18px;
  /* vertical-align: middle; */
}
.amount {
  font-size: 30px;
  width: 70px;
  height: 45px;
  padding: 0;
  margin: 0;
  border: none;
  text-align: right;
}
#filter div span {
  display: table;
  position: absolute;
}
#filter div {
  display: inline-block;
  width: 100px;
}
#filter table {
  margin: auto;
}
.options {
  position: relative;
  width: 60px;
  height: 30px;
  font-size: 0.9em;
}
textarea {
  border: 1px solid lightgray;
  width: 50%;
}
</style>